// import * as cadApi from '@/cadApi'
// import { EventBus } from '@/EventBus'

import * as turf from '@turf/turf'

function offset(feature, lngOffs, latOffs) {
  function offsetCoords(coords) {
    if (Array.isArray(coords[0])) {
      return coords.map(offsetCoords)
    }
    return [coords[0] + lngOffs, coords[1] + latOffs]
  }
  if (feature.type === 'FeatureCollection') {
    return {
      ...feature,
      features: feature.features.map(f => offset(f, lngOffs, latOffs)),
    }
  }
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: offsetCoords(feature.geometry.coordinates),
    },
  }
}

window.offset = offset

async function loadData(map) {
  const features = await ((await fetch('/map/serenity.geojson')).json())
  window.g = features
  // Data is in the correct projection but strangely mislocated by a kilometre or two.
  const featuresTransformed = offset(features, 0.02326, 0.01755)
  // console.log(featuresTransformed)
  map.U.setData('lots', featuresTransformed)
}

// eslint-disable-next-line func-names
window.adjust = function (x, y) {
  window.map.U.setData('lots', offset(window.g, x, y))
}

// eslint-disable-next-line func-names
export default function (map, { lotSelectedCb }) {
  if (!map.getSource('lots')) {
    // map.U.addVector('lots', cadApi.lotsTiles())
    map.U.addGeoJSON('lots', '/map/serenity.geojson', { generateId: true })
    loadData(map)
    map.U.addLine('lotsLineHit', 'lots', {
      lineColor: 'transparent',
      lineWidth: 4,
      filter: ['==', 'CODE', 'LOT'],
    })
    map.U.addLine('lotsLine', 'lots', {
      lineColor: 'hsl(200,80%,80%)',
      lineWidth: 2,
      filter: ['==', 'CODE', 'LOT'],
    })
    map.U.addLine('lotsLineHover', 'lots', {
      lineColor: ['case', ['to-boolean', ['feature-state', 'hover']], 'hsl(200,80%,50%)', 'transparent'],
      lineWidth: 4,
      filter: ['==', 'CODE', 'LOT'],
    })

    map.U.addFill('lotsFill', 'lots', {
      fillColor: 'hsl(200,40%,50%)',
      fillOpacity: 0.3,
      filter: ['==', 'CODE', 'LOT'],
    })
    let activeFeature
    map.U.hoverPointer('lotsFill')
    map.on('mousemove', 'lotsFill', e => {
      const f = e.features[0]
      if (f.id !== activeFeature) {
        if (activeFeature !== undefined) {
          map.setFeatureState({ id: activeFeature, source: 'lots' }, { hover: false })
        }
        map.setFeatureState({ id: f.id, source: 'lots' }, { hover: true })
        activeFeature = f.id

        // console.log(e.features[0])
      }
    })
    map.on('mouseleave', 'lotsFill', () => {
      if (activeFeature !== undefined) {
        map.setFeatureState({ id: activeFeature, source: 'lots' }, { hover: false })
      }
    })
    // map.U.hoverFeatureState('lotsFill')
    map.on('click', 'lotsFill', e => {
      const f = e.features[0]
      f.properties.calcArea = turf.area(f)

      lotSelectedCb?.(e.features[0])
    })
    // EventBus.$on('lots-imported', () => {
    //   map.getSource('lots').setTiles([cadApi.lotsTiles()])
    // })
  }
}
