<template lang="pug">
  div(style="position:relative")
    #InventoryMap(style="width: 100%; height: 1100px")
    MapPopup(:map="map" :lngLat="popupLngLat" :selectedLot="selectedLot")
</template>

<script>
import mapboxgl from 'mapbox-gl'
import glutils from 'mapbox-gl-utils'
import * as turf from '@turf/turf'
import MapPopup from './MapPopup.vue'
import initLots from './layers/lots'
import initMasterplan from './layers/masterplan'

mapboxgl.accessToken = 'pk.eyJ1Ijoic3RldmFnZSIsImEiOiJja3VmYnpsOTMxdDRlMnVxbHNoYXV3Yjl0In0.TRPsdvEPJUBYymhyrvtyNw'
export default {
  name: 'InventoryMap',
  components: { MapPopup },
  data: () => ({ map: null, popupLngLat: null, selectedLot: null }),
  created() {
    window.InventoryMap = this
  },
  async mounted() {
    this.map = new mapboxgl.Map({
      container: 'InventoryMap',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [
        144.65432917651498,
        -37.738320055949885,
      ],
      zoom: 17.5,
    })
    window.map = this.map
    glutils.init(this.map, mapboxgl)
    await this.map.U.onLoad()
    this.map.on('click', () => {
      this.selectedLot = null
      this.popupLngLat = null
    })
    initMasterplan(this.map)
    initLots(this.map, { lotSelectedCb: this.selectLot.bind(this) })

    this.initMap()
  },
  methods: {
    initMap() {
      this.popupLngLat = [144.653, -37.738]
    },
    selectLot(feature) {
      this.popupLngLat = turf.centroid(feature).geometry.coordinates
      this.selectedLot = feature
      this.map.panTo(this.popupLngLat, { padding: { top: 350 } })
    },
  },
}
</script>

<style scoped></style>
